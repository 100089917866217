<template>
	<div class="pages-index">

		<!-- 特效开关 -->
		<!-- <vue-particles color="#8EB5C9" :particleOpacity="0.9" :particlesNumber="80" shapeType="circle" :particleSize="6"
			linesColor="#8EB5C9" :linesWidth="1" :lineLinked="true" :lineOpacity="0.6" :linesDistance="150"
			:moveSpeed="3" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push" class="all-page">
		</vue-particles> -->

		<!-- 顶部Banner -->
		<div class="carousel-banner">
			<swiper :options="swiperOptionBanner">
				<!-- slides -->
				<swiper-slide class="item1">
					<img class="banner-img" src="https://dh123.hndh6666.com/images/home_top.png" />
				</swiper-slide>
				<swiper-slide class="item2">
					<img class="banner-img" src="https://dh123.hndh6666.com/images/temp5_top.png" />
				</swiper-slide>
				<swiper-slide class="item3">
					<img class="banner-img" src="https://dh123.hndh6666.com/images/temp6_topc" />
				</swiper-slide>


				<!-- Optional controls -->
				<div class="swiper-pagination swiper-banner-pagination" slot="pagination"></div>
				<!-- <div class="swiper-button-prev swiper-banner-button-prev" slot="button-prev"></div>
				<div class="swiper-button-next swiper-banner-button-next" slot="button-next"></div> -->
			</swiper>
		</div>
		
		
		<div class="bgbox-gray module1-conts">
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:20,offset:2}" :lg="{span:10,offset:7}">
				<div class="module1-conts">
					<div class="module-title">
						<span class="title-cn">关于德合商服</span>
						<span class="title-en">ABOUT US</span>
					</div>
				</div>
			</el-col>
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }"
				style="margin-top: 50px;">
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
					<div class="module1-item wow slideInLeft" style="">
						<div class="miBox2">
							<img style="" src="https://dh123.hndh6666.com/images/home_02.png" />
						</div>
					</div>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 12 }">
					<div class="module1-item wow slideInRight">
						<div class="miBox2">
							<div class="core-css wow slideInRight">
								<div class="core-css01" style="margin-top: 20px;">
									<div class="core-txt">
										<div class="core-title2">德合商服是中国新零售移动支付及行业软件解决
										方案提供商，国内移动支付领先合作伙伴，用智
										能技术“新引擎”赋能新零售，以科技服务市场。
										秉承技术赋能，以诚信凝聚伙伴、共建生态的宗
										旨、实现共同的事业理想。</div>
										<div class="core-title2">感谢您关注德合商服，与我们一起创造新零售信息
										化科技典范，以技术驱动数字新零售发展，以科技
										带给消费者愉悦感受，选择德合商服，你将获得更
										大的价值。</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-col>
		</div>


		<div class="bgbox-gray module1-conts">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }"
				style="margin-top: 50px;">
				<el-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 12 }">
					<div class="module1-item wow slideInLeft">
						<div class="miBox2">
							<div class="core-css wow slideInLeft">
								<div class="core-css01" style="margin-top: 20px;">
									<div class="core-txt">
										<div class="core-title">我们的使命</div>
										<div class="core-msg">OUR MISSION</div>
										<div class="core-cont">用科技创新和服务运营提高新零售事业发展</div>
										<div class="core-cont">Improve the development of new retail business through technological innovation and service operation</div>
									</div>
								</div>
								<div class="core-css01 mgt50">
									<div class="core-txt">
										<div class="core-title">品牌定位</div>
										<div class="core-msg">BRAND POSITIONING</div>
										<div class="core-cont">中国新零售移动支付及行业软件专家</div>
										<div class="core-cont">China new retail mobile payment and industry software experts</div>
									</div>
								</div>
								<div class="core-css01 mgt50">
									<div class="core-txt">
										<div class="core-title">企业精神</div>
										<div class="core-msg">THE SPIRIT OF ENTERPRISE</div>
										<div class="core-cont">敬业、团结、创新、服务，共赢</div>
										<div class="core-cont">Dedication, unity, innovation, service, win-win</div>
									</div>
								</div>
								<div class="core-css01 mgt50">
									<div class="core-txt">
										<div class="core-title">经营思想</div>
										<div class="core-msg">MANAGEMENT PHILOSOPHY</div>
										<div class="core-cont">科技服务，技术赋能、诚信凝聚伙伴的成功及员工的成功</div>
										<div class="core-cont">Technology service, technology empowerment, integrity cohesion partner success and staff success</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
					<div class="module1-item wow slideInRight" style="">
						<div class="miBox2">
							<img style="max-width: 579px;max-height: 808px;" src="https://dh123.hndh6666.com/images/home_01.png" />
						</div>
					</div>
				</el-col>
			</el-col>
		</div>
		
		

	</div>
</template>

<script>
	import 'swiper/dist/css/swiper.css'
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'

	export default {
		components: {
			swiper,
			swiperSlide
		},
		data() {
			return {
				showBigImg: false,
				bigImgSrc: null,
				swiperOptionBanner: {
					autoHeight: true,
					slidesPerView: 1, //显示数量
					loop: true,
					autoplay: 5000,
					effect: 'fade', //渐变
					pagination: {
						el: '.swiper-banner-pagination',
						clickable: true,
					},
					navigation: {
						nextEl: '.swiper-banner-button-next',
						prevEl: '.swiper-banner-button-prev',
					}
				},
				swiperOptionCard: {
					slidesPerView: 4, //显示数量
					spaceBetween: 10,
					centeredSlides: false, //居中slide
					loop: false,
					autoplay: false,
					breakpoints: {
						640: {
							slidesPerView: 1,
							spaceBetween: 0,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 20,
						},
						992: {
							slidesPerView: 3,
							spaceBetween: 20,
						}
					},
					pagination: {
						el: '.swiper-card-pagination',
						clickable: true,
					},
					navigation: {
						nextEl: '.swiper-card-button-next',
						prevEl: '.swiper-card-button-prev',
					}
				},
				swiperOptionSdwan: {
					autoHeight: true,
					slidesPerView: 1, //显示数量
					loop: true,
					autoplay: {
						delay: 5000,
						disableOnInteraction: false //点击切换后 继续自动播放
					},
					pagination: {
						el: '.swiper-sdwan-pagination',
						clickable: true,
					},
					navigation: {
						nextEl: '.swiper-sdwan-button-next',
						prevEl: '.swiper-sdwan-button-prev',
					}
				}
			}
		},
		mounted() {

		},
		methods: {

		}
	};
</script>

<style lang="scss">
	.itemview {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		.centerv {
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			img {
				width: 100%;
				margin: 0 auto;
				max-width: 312px;
				max-height: 312px;
				margin: 1px;
			}
		}

	}

	.fourImg {
		margin-left: 10px;
		width: 572px;
		height: 390px;
	}


	.partner-box {
		.swiper-container {
			width: 100%;
		}

		.swiper-wrapper {
			padding-bottom: 20px;
		}

		.swiper-pagination {
			bottom: 0;
		}

		.swiper-pagination-bullet {
			width: 18px !important;
			border-radius: 0;
		}
	}
	
	.module1-conts {
		
		.module-title {
			text-align: center;
			padding-bottom: 40px;

			.title-cn {
				display: block;
				font-size: 30px;
				font-weight: bold;
				color: #1f1f1f;
			}

			.title-en {
				display: block;
				font-size: 12px;
				font-weight: 400;
				color: #757575;
				line-height: 20px;
			}
		}

		.module1-msg {
			font-size: 18px;
			color: #757575;
			margin: 0 auto 10px auto;
			width: 100%;
			max-width: 840px;
			text-indent: 2em;

			span {
				display: inline-block;
				// text-align: center;
			}
		}

		.module1-item {
			// border: 1px solid #E4E4E4;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			// width: 100%;
			padding: 10px;

			// box-sizing: border-box;
			img {
				display: block;
				width: 100%;
				// max-width: 351px;
				margin: 0 auto;
				transition: all 0.3s ease;

				&:hover {
					transform: translate3d(0, -2px, 0);
					box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
				}
			}

			.miBox {
				max-width: 572px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

			}

			.miBox2 {
				max-width: 580px;
				
				// background-color: #ffaaff;
				// max-height: 700px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.core-css {
				display: flex;
				flex-direction: column;
				margin-top: -20px;
				// background-color: #EC9023;
				// justify-content: center;
				// align-items: center;
				// margin-left: 30px;
				
				.mgt50{
					margin-top: 45px;
				}
					
				.core-css01 {
					display: flex;
					flex-direction: row;
					align-items: center;

					.core-img {
						// display: block;
						// width: 100%;
						max-width: 48px;
						// max-height: 48px !important;
						margin: 0 auto;
						transition: all 0.3s ease;

						&:hover {
							transform: translate3d(0, -2px, 0);
							box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
						}
					}

					.core-txt {
						display: flex;
						flex-direction: column;
						margin-left: 10px;
						.core-title{
							color: #0075C1;
							font-size: 28px;
						}
						.core-title2{
							margin-top: 50px;
							color: #231815;
							font-size: 28px;
							text-indent: 2em;
						}
						.core-msg{
							margin-top: 20px;
							color: #0075C1;
							font-size: 20px;
						}
						.core-cont{
							margin-top: 10px;
							color: #231815;
							font-size: 15px;
						}
					}
				}

			}



			.cpsj-css {
				width: 100%;
				max-width: 572px;
				margin: 0 auto;
				margin: 10px 15px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}

			.cpsj-more {
				width: 128px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				border: 1px solid #EC9023;
				;
				color: #EC9023;
				border-radius: 100px;
			}
		}

		.module2-item {
			width: 100%;
			box-sizing: border-box;
			margin-left: 20px;
		}
	}

	#particles-js {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}

	#particles-content {
		position: absolute;
		width: 100%;
		top: 0%;
		bottom: 10%;

	}


	#particles-content .big-title {
		width: 100%;
		height: 70%;
		// background-image: url(../../assets/images/top_banner2X_title.png);
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: 50% 50%;
	}


	#particles-content .desc-title {
		padding-top: 2%;
		text-align: center;
		color: rgba(255, 255, 255, .8);
		font-size: 32px;
		height: 30%;
	}

	#particles-content .desc-title-top {
		padding-top: 2%;
		text-align: center;
		color: rgba(255, 255, 255, .8);
		font-size: 62px;
		font-weight: 800;
		height: 50%;
	}




	.home-top {}


	.top-page {
		background-size: cover;
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		width: 818;
		height: 869;
		overflow: hidden;
	}

	.all-page {
		background-size: cover;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
</style>

<style lang="scss" scoped>
	.article {
		font-size: 16px;
		text-align: center;
	}

	.partner-box {
		.swiper-container {
			width: 100%;
		}

		.swiper-wrapper {
			padding-bottom: 20px;
		}

		.swiper-pagination {
			bottom: 0;
		}

		.swiper-pagination-bullet {
			width: 18px !important;
			border-radius: 0;
		}
	}
	.carousel-banner {
		
		width: 100%;
		position: relative;
		
		.swiper-pagination {
			display: flex;
			align-items: center;
			position: absolute;
			width: auto;
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
			background-color: rgba(0, 0, 0, 0.1);
			border-radius: 11px;
			padding: 6px 16px;
		}
	
		.swiper-pagination-bullet {
			width: 6px !important;
			height: 6px !important;
			background: #fff !important;
			opacity: .8;
		}
	
		.swiper-pagination-bullet-active {
			background: transparent !important;
			border: 2px solid #fff !important;
		}

		.swiper-container {
			height: 100%;
			width: 100%;
		}

		.swiper-slide .banner-img {
			width: 100%;
		}

		.swiper-button-prev,
		.swiper-button-next {
			outline: none;
			height: 30px;
		}

		.item1 .details-box {
			width: 30%;
			position: absolute;
			right: 18%;
			top: 10%;

			img {
				width: 100%;
				max-width: 570px;
			}

			.btn {
				margin-top: 30px;
				float: right;
			}
		}

		.item2 {
			.details-box {
				width: 30%;
				max-width: 510px;
				position: absolute;
				right: 18%;
				top: 28%;

				img {
					width: 100%;
					max-width: 510px;
				}

				.btn {
					margin-top: 10px;
				}
			}
		}

		.item3 {
			.details-box {
				width: 30%;
				max-width: 515px;
				position: absolute;
				left: 18%;
				top: 28%;

				img {
					width: 100%;
				}

				.btn {
					margin-top: 10px;
				}
			}
		}

		.item4 {
			.details-box {
				width: 30%;
				max-width: 676px;
				position: absolute;
				left: 18%;
				top: 28%;
				text-align: center;

				img {
					width: 100%;
				}

				.btn {
					margin-top: 10px;
				}
			}
		}
	}

	@media screen and (max-width: 767px) {
		.carousel-banner {
			.banner-img {
				height: 270px;
				object-fit: cover;
			}

			.item1 .details-box {
				width: 38%;
				position: absolute;
				right: 10%;
				top: 10%;

				img {
					width: 100%;
				}

				.btn {
					margin-top: 10px;
					float: right;
				}
			}

			.item2 {
				.banner-img {
					object-position: left;
				}

				.details-box {
					width: 260px;
					position: absolute;
					right: 16%;
					top: 12%;
					padding: 10px;
					box-sizing: border-box;
					// background: rgba($color: #000000, $alpha: 0.7);
					border-radius: 6px;

					img {
						width: 100%;
					}

					.btn {
						margin-top: 10px;
					}
				}
			}

			.item3 {
				.banner-img {
					object-position: right;
				}

				.details-box {
					width: 260px;
					position: absolute;
					left: 12%;
					top: 12%;
					padding: 10px;
					box-sizing: border-box;
					// background: rgba($color: #000000, $alpha: 0.7);
					border-radius: 6px;
				}
			}

			.item4 {
				background: #977144;

				.banner-img {
					opacity: 0;
				}

				.details-box {
					width: 300px;
					position: absolute;
					left: 50%;
					top: 12%;
					padding: 10px;
					box-sizing: border-box;
					transform: translateX(-50%);
				}
			}
		}
	}

	.flex-col {
		display: flex;
		align-items: center;
	}

	.bgbox-white {
		padding: 50px 0;
		overflow: hidden;
		background: #fff;
	}

	.bgbox-gray {
		padding: 50px 0;
		overflow: hidden;
		background: #f7f7f7;
	}

	.module-title {
		text-align: center;
		font-size: 26px;
		font-weight: bold;
		line-height: 1;
		padding-bottom: 14px;
		position: relative;
	}

	.fc-white {
		color: #fff;
	}

	.module-title.fc-white::after {
		content: "";
		width: 80px;
		height: 4px;
		background: #fff;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -40px;
		border-radius: 5px;
	}

	.card-show {
		overflow: hidden;

		.item {
			float: left;
			width: 100%;
			margin: 30px 0px 20px 0px;
			// background: #ff5500;
			padding: 20px 10px 20px 10px;
			box-sizing: border-box;
			transition: all 0.3s ease;

			h3 {
				font-size: 20px;
				color: #333;
				padding-bottom: 5px;
			}

			h4 {
				height: 30px;
				font-size: 13px;
				color: #333;
				font-weight: 400;
				margin-bottom: 20px;
			}

			p {
				height: 60px;
				font-size: 16px;
				color: #666;
				margin: 20px 0;
			}

			.img-box {
				width: 100%;
				max-height: 390px;
				overflow: hidden;

				img {
					max-width: 471px;
					max-height: 390px;
					object-fit: cover;
					transition: all 0.3s ease;
				}
			}
		}

		.item:hover {
			cursor: pointer;
			// box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
			transform: translate3d(0, -2px, 0);

			img {
				transform: scale(1.1);
			}

			.el-button {
				color: #409EFF;
				border-color: #c6e2ff;
				background-color: #ecf5ff;
			}
		}

		.item:last-child {
			margin-right: 0;
		}

		.swiper-button-prev,
		.swiper-button-next {
			outline: none;
			height: 30px;
		}
	}

	.bgbox-img {
		background-size: 100% 100%;
	}

	.project-iptv-item {
		margin-bottom: 20px;
	}

	.project-iptv-item img {
		cursor: pointer;
		vertical-align: middle;
		width: 100%;
		transition: all 0.5s ease;
	}

	.project-iptv-item p {
		font-size: 20px;
		color: #fff;
		text-align: center;
		line-height: 30px;
	}

	.partner-box {
		overflow: hidden;
		width: 100%;
		padding: 40px 10px 0;
		box-sizing: border-box;

		.img-box {
			padding: 5px;
			box-sizing: border-box;

			img {
				width: 100%;
				vertical-align: middle;
				border: 1px solid #eee;
				box-sizing: border-box;
				transition: all 0.5s ease;

				&:hover {
					box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
				}
			}
		}
	}

	// 移动端css
	@media screen and (max-width: 767px) {
		.cpsj-width {
			width: 100% !important;
		}
	}

	// 991
	@media screen and (max-width: 991px) {
		.flex-row {
			display: block;
		}
	}
</style>
